<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="wrapper">
    <v-card class="usage white-card top-actions-card">
      <v-card-title>
        <v-icon class="white--text mr-3">fas fa-clipboard-list-check</v-icon>
        <span class="title">Top {{ topCount }}</span>
      </v-card-title>
      <v-card-text class="top-actions-body">
        <v-data-table
            :headers="headers"
            :items="topActions"
            hide-default-footer
            no-data-text="Er zijn geen open acties"
        >
          <template v-slot:item="props">
            <tr @click="openActionDialog(props.item.id)">
              <td>{{ props.item.action.name }}</td>
              <td>{{ props.item.yearly_saving | currency }}</td>
              <td>{{ props.item.investment | currency }}</td>
              <td>{{ props.item.payback_time | year }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :to="{name:'action'}" text color="accent">
          Acties
        </v-btn>
      </v-card-actions>
    </v-card>
    <action-dialog :action-id="actionDialogId" ref="actionDialog"></action-dialog>
  </div>
</template>

<script>
import meterType from './../../mixins/meterTypes.js';
import ActionDialog from './ActionDialog.vue';

export default {
  name: 'TopActions',
  components: { ActionDialog },
  mixins: [meterType],
  data() {
    return {
      actionDialogId: null,
      topActions: [],
      topCount: 5,
      headers: [
        {
          text: 'Actie',
          align: 'left',
          sortable: false,
          value: 'name',
          width: 140,
        },
        {
          text: 'Besparing',
          align: 'left',
          value: 'saving',
          sortable: false,
          width: 80,
        },
        {
          text: 'Investering',
          align: 'left',
          value: 'investment',
          sortable: false,
          width: 80,
        },
        {
          text: 'Terugverdientijd',
          align: 'left',
          value: 'payback_time',
          sortable: false,
          width: 120,
        },
      ],
    };
  },
  created() {
    this.getTopActions();
  },
  methods: {
    getTopActions() {
      this.$http.get('me/organisation/current/meter-action/top?perPage=' + this.topCount).then((response) => {
        this.topActions = response.data.data;
      });
    },
    openActionDialog(actionId) {
      this.$refs.actionDialog.open();
      this.actionDialogId = actionId;
    },
  },
};
</script>

<style>
.top-actions-card {
  box-sizing: content-box;
  width:      100%;
}

.top-actions-card > .v-card__actions {
  padding-top: 0px;
}

.top-actions-body, .top-actions-body .v-table__overflow {
  height:     250px;
  max-height: 250px;
  padding:    0px;
}

.v-card__text.top-actions-body > div {
  height: auto;
}

.v-card__text.top-actions-body td, .v-card__text.top-actions-body th {
  max-width:     100px;
  overflow:      hidden;
  text-overflow: ellipsis;
  white-space:   nowrap;
}

.v-card__text.top-actions-body td:first-child, .v-card__text.top-actions-body th:first-child {
  max-width: 250px;
}


.v-card__text.top-actions-body th {
  padding: 0 12px !important;
}

.headline {
  white-space:   nowrap;
  overflow:      hidden;
  text-overflow: ellipsis;
  width:         80%;
}
</style>

<style scoped lang="scss">

::v-deep .v-card__text.top-actions-body td {
  padding: 8px 12px !important;
  height:  auto;
}

</style>
