<template>
    <v-row justify="space-between" class="ma-0 page-home">
      <v-col class="mb-4" md="4" cols="12">
        <v-card class="savings primary-card fill-height" color="primary" dark>
          <v-card-title>
            <v-icon class="white--text mr-3">far fa-lightbulb-dollar</v-icon>
            <span class="title">Besparingen</span>
          </v-card-title>
          <v-card-text>
            <h1 v-if="notFound">
              Er zijn geen uitgevoerde acties
            </h1>
            <saving @notFound="notFound  = true" v-else></saving>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-4" md="8" cols="12" :class="{'pl-4': $vuetify.breakpoint.mdAndUp}">
        <top-actions></top-actions>
      </v-col>
      <v-col class="mb-4" md="4" cols="12" :class="{'mb-4': $vuetify.breakpoint.smAndDown}">
        <summation :calculation-date="$moment().format('YYYY-MM-DD')"></summation>
      </v-col>
      <v-col class="mb-4" md="8" cols="12" :class="{'pl-4': $vuetify.breakpoint.mdAndUp}">
        <v-card class="usage white-card">
          <v-card-title>
            <v-icon class="white--text mr-3">far fa-chart-bar</v-icon>
            <span class="title">Verbruik</span>
          </v-card-title>
          <v-card-text class="pa-3">
            <usage :meters="[]"
                   electricity
                   gas
                   unit="hour"
                   v-if="weekUsage"
                   :from="weekUsage.from"
                   :to="weekUsage.to"></usage>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :to="{name:'usage'}" text color="accent">Verbruik</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import TopActions from '../components/actions/TopActions';
import Saving from '../components/charts/Saving.vue';
import Usage from '../components/charts/Usage.vue';
import Summation from '../components/Summation/Summation.vue';

export default {
  name: 'home',
  components: {
    TopActions,
    Summation,
    Saving,
    Usage,
  },
  data() {
    return {
      notFound: false,
      weekUsage: null,
    };
  },
  created() {
    this.getUsageData();
  },
  methods: {
    getUsageData() {
      this.weekUsage = {
        from: this.$moment().subtract(7, 'd').format('YYYY-MM-DD 00:00:00'),
        to: this.$moment().format('YYYY-MM-DD 00:00:00'),
      };
    },
  },
};
</script>
<style>
.page-home {
  width: 100%;
}

.savings canvas {
  height: 260px !important;
}

.usage canvas {
  height: 304px;
}

.usage .v-card__text {
  padding-bottom: 0px;
}
</style>
